import React, {useContext, useEffect, useState} from 'react'
import ImagenEstandar from './ImagenEstandar'
import TextoTiempoEspera from '../Agenda/TextoTiempoEspera'
import AlertaDinamica from './AlertaDinamica'
import {BotonSimple} from '../Botones'
import {ContextApplication} from '../../contexto'
import {ObtenerObservacionMedicaPorAtencionId} from '../../microservicios'
import {useMsal} from '@azure/msal-react'
import {ROLES_APP} from '../../constantes'

const CuadroInformacionUsuario = ({
  estilosContenedor,
  titulo,
  tituloImagen,
  descripcionPrimeraLinea,
  descripcionSegundaLinea,
  datos,
  urlFoto,
  deshabilitarTiempo,
  atencionId = 0,
}) => {
  const {accounts} = useMsal()
  const rolUsuario = accounts[0]?.idTokenClaims?.roles[0] ?? ''

  const ROLES_AUTORIZADOS_OBSERVACIONES_MEIDCAS = [
    ROLES_APP.MEDICO_OCUPACIONAL,
    ROLES_APP.GERENTE_GENERAL,
  ]

  const validarRolPuedeVerObservaciones = (rol) => {
    return ROLES_AUTORIZADOS_OBSERVACIONES_MEIDCAS.includes(rol)
  }

  const contextoAplicacion = useContext(ContextApplication)

  const cerrarModal = (modalName) => {
    contextoAplicacion[modalName]({
      abierto: false,
      titulo: '',
      contenido: <></>,
      botones: [],
    })
  }
  const [infoObservacionesMedicas, setInfoObservacionesMedicas] = useState({
    atencionId: 0,
    observacion: '',
    ordenId: 0,
  })

  useEffect(() => {
    const obtenerObservacionesMedicas = async () => {
      try {
        const observacionMedica = await ObtenerObservacionMedicaPorAtencionId(
          atencionId
        )
        if (observacionMedica.status === 200)
          setInfoObservacionesMedicas(observacionMedica?.data)
      } catch (error) {
        console.log(error)
      }
    }
    if (atencionId) obtenerObservacionesMedicas()
  }, [])

  const mostrarObservacionesMedicas = (numeroOrden, ObservacionMedica) => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: '',
      contenido: (
        <div className="w-full flex justify-center items-center flex-wrap">
          <span className="w-full text-center text-cendiatra text-xl font-bold">
            OBSERVACIONES MÉDICAS DE PORTAL CLIENTE
          </span>

          <span className="w-full text-center text-cendiatra text-xl font-bold">
            ORDEN: {numeroOrden}
          </span>

          <div
            className="w-full overflow-y-auto p-4 mt-10"
            style={{maxHeight: '200px'}}
          >
            <span className="w-full flex justify-center items-center text-center text-cendiatra uppercase">
              {ObservacionMedica}
            </span>
          </div>
        </div>
      ),
      botones: [
        {
          nombre: 'Regresar',
          click: () => {
            cerrarModal('setModal')
          },
        },
      ],
      clasesAdicioneles: 'w-5/12 bg-cendiatra-gris-5',
    })
  }

  return (
    <div className={`${estilosContenedor}`}>
      <div className="w-full flex justify-center items-center text-center mb-4 ">
        <div className="w-1/3"></div>
        <div className="w-1/3 mb-10">
          <span className="text-17px text-cendiatra font-bold">{titulo}</span>
        </div>
        <div className="w-1/3 flex justify-end">
          {Boolean(deshabilitarTiempo) ? null : (
            <div className="w-auto h-auto flex justify-center items-center flex-wrap">
              <span className="text-cendiatra text-13px w-full">
                Tiempo de atención
              </span>
              <TextoTiempoEspera
                tiempoInicial={'00:00:00'}
                colorTexto={'text-cendiatra font-bold text-13px'}
              />
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex justify-center items-center -mt-10 flex-wrap">
        <div className="w-3/12 flex justify-center items-center flex-wrap text-center -mt-5">
          <ImagenEstandar
            imagen={urlFoto}
            estilosContenedor={'w-full flex justify-center items-center my-3'}
            estilosImagen={'w-40 h-40 rounded-full'}
            textoAlternativo={'foto'}
          />
          <span className="w-full font-bold text-cendiatra text-14px">
            {tituloImagen}
          </span>
          <span className="w-full font-bold text-cendiatra-gris-1 text-13px">
            {descripcionPrimeraLinea}
          </span>
          <span className="w-full font-bold text-cendiatra-gris-1 text-13px">
            {descripcionSegundaLinea}
          </span>
        </div>

        <div className="w-9/12 flex justify-start items-center flex-wrap">
          {datos.map((item) => (
            <div
              key={item.titulo}
              className="w-1/3 flex-wrap my-2 pl-1 text-cendiatra-gris-1 text-13px uppercase mb-2"
            >
              <span className="text-cendiatra text-13px">
                {item.titulo + ': '}
              </span>
              {item.contenido}
            </div>
          ))}
          <div className="w-1/3 flex justify-start items-center flex-wrap text-center text-xs">
            {infoObservacionesMedicas.atencionId &&
            infoObservacionesMedicas.observacion &&
            validarRolPuedeVerObservaciones(rolUsuario) ? (
              <>
                <div className="w-9/12">
                  <AlertaDinamica
                    mostrarAlerta={true}
                    mensaje={'OBSERVACIONES MÉDICAS DE PORTAL CLIENTE'}
                  />
                </div>
                <div className="w-3/12 flex justify-start items-center mt-2">
                  <BotonSimple
                    texto={'Ver'}
                    estilosBoton={
                      'w-24 h-8  rounded-lg bg-btnBg bg-cover bg-no-repeat bg-center text-white  font-bold text-14px '
                    }
                    funcion={() =>
                      mostrarObservacionesMedicas(
                        infoObservacionesMedicas?.ordenId,
                        infoObservacionesMedicas?.observacion
                      )
                    }
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CuadroInformacionUsuario
