import {useMsal} from '@azure/msal-react'
import React, {useState, createContext, useEffect, useMemo} from 'react'
import {fnObtenerInformacionSessionOLocalStorage} from '../utilidades/window.utils'

export const ContextApplication = createContext('Default Value')

export const Provider = ({children}) => {
  const [errorTamano, setErrorTamano] = useState(false)
  const [cargaImagen, setcargaImagen] = useState(false)
  const [urlCargaImagen, setUrlCargaImagen] = useState('')
  const [userFormInfo, setUserFormInfo] = useState([{}])
  const [isLoading, setIsLoading] = useState(false)
  const [siteName, setSiteName] = useState('')
  const [showDoctorMenu, setShowDoctorMenu] = useState(false)
  const [patientAgendaInfo, setPatientAgendaInfo] = useState([])
  const [offlineMode, setOfflineMode] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('Cargando memoria caché')
  const [modal, setModal] = useState({
    abierto: false,
    titulo: '',
    contenido: '',
    botones: [],
    clasesAdicioneles: '',
    cerrar: () => {},
    removerEspacios: false,
  })
  const [modal2, setModal2] = useState({
    abierto: false,
    titulo: '',
    contenido: '',
    botones: [],
    clasesAdicioneles: '',
    cerrar: () => {},
    removerEspacios: false,
  })

  const [sede, setSede] = useState({
    sedeId: '',
    recepcionId: '',
    consultorioId: '',
    ciudad: '',
    nombreSede: '',
    telefono: '',
    direccion: '',
    urlLogo: '',
  })
  const modalMap = useMemo(() => ({setModal, setModal2}), [])
  const [countFetching, setCountFetching] = useState(0)
  const {accounts} = useMsal()
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const [gruposSanguineos, setGruposSanguineos] = useState([])
  const [estadosCiviles, setEstadosCiviles] = useState([])
  const [escolaridades, setEscolaridades] = useState([])
  const [razas, setRazas] = useState([])
  const [mediosDePago, setMediosDePago] = useState([])
  const [sexos, setSexos] = useState([])
  const [zonas, setZonas] = useState([])
  const [estratos, setEstratos] = useState([])
  const [indicativos, setIndicativos] = useState([])
  const [facturarA, setFacturarA] = useState([])
  const [tipoExamen, setTipoExamen] = useState([])
  const [actividadesEconomicas, setActividadesEconomicas] = useState([])
  const [eps, setEps] = useState([])
  const [arl, setArl] = useState([])
  const [tipoAtencion, setTipoAtencion] = useState([])
  const [fondoPension, setFondoPension] = useState([])
  const [deshabilitarMenu, setDeshabilitarMenu] = useState(false)
  const [mostrarModalMenu, setMostrarModalMenu] = useState(false)
  const [usuariosRecepcion, setUsuariosRecepcion] = useState([])
  const [tiposPoblacion, setTiposPoblacion] = useState([])
  const [mostrarSpinner, setMostrarSpinner] = useState(false)

  useEffect(() => {
    const esNavegacion = window.performance
      .getEntriesByType('navigation')
      .map((nav) => nav.type)
      .includes('navigate')
    const esRecarga = window.performance
      .getEntriesByType('navigation')
      .map((nav) => nav.type)
      .includes('reload')

    const llaves = {
      sedeId: obtenerValorORetornarVacioLocalStorage('sedeId'),
      consultorioId: obtenerValorORetornarVacioLocalStorage('consultorioId'),
      recepcionId: obtenerValorORetornarVacioLocalStorage('recepcionId'),
    }

    if (esNavegacion || esRecarga) {
      setSede((prv) => ({
        ...prv,
        ...llaves,
      }))
    }
  }, [])

  const obtenerValorORetornarVacioLocalStorage = (key) => {
    return fnObtenerInformacionSessionOLocalStorage(key, rol) ?? null
  }

  const cerrarAppModal = () => {
    setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
      cerrar: () => {},
      clasesAdicioneles: 'w-1/2',
    })
  }
  const cerrarModal = (modalFn) => {
    modalMap[modalFn]({
      abierto: false,
      titulo: '',
      contenido: <></>,
      botones: [],
    })
  }

  return (
    <ContextApplication.Provider
      value={{
        userFormInfo: userFormInfo,
        setUserFormInfo: setUserFormInfo,
        isLoading: isLoading,
        setIsLoading: setIsLoading,
        siteName: siteName,
        setSiteName: setSiteName,
        showDoctorMenu: showDoctorMenu,
        setShowDoctorMenu: setShowDoctorMenu,
        patientAgendaInfo: patientAgendaInfo,
        setPatientAgendaInfo: setPatientAgendaInfo,
        offlineMode: offlineMode,
        setOfflineMode: setOfflineMode,
        loadingMessage: loadingMessage,
        setLoadingMessage: setLoadingMessage,
        modal: modal,
        setModal: setModal,
        modal2: modal2,
        setModal2: setModal2,
        sede: sede,
        setSede: setSede,
        gruposSanguineos: gruposSanguineos,
        setGruposSanguineos: setGruposSanguineos,
        estadosCiviles: estadosCiviles,
        setEstadosCiviles: setEstadosCiviles,
        escolaridades: escolaridades,
        setEscolaridades: setEscolaridades,
        razas: razas,
        setRazas: setRazas,
        mediosDePago: mediosDePago,
        setMediosDePago: setMediosDePago,
        sexos: sexos,
        setSexos: setSexos,
        zonas: zonas,
        setZonas: setZonas,
        estratos: estratos,
        setEstratos: setEstratos,
        indicativos: indicativos,
        setIndicativos: setIndicativos,
        facturarA: facturarA,
        setFacturarA: setFacturarA,
        tipoExamen: tipoExamen,
        setTipoExamen: setTipoExamen,
        eps: eps,
        setEps: setEps,
        arl: arl,
        setArl: setArl,
        tipoAtencion: tipoAtencion,
        setTipoAtencion: setTipoAtencion,
        fondoPension: fondoPension,
        setFondoPension: setFondoPension,
        errorTamano: errorTamano,
        setErrorTamano: setErrorTamano,
        cargaImagen: cargaImagen,
        setcargaImagen: setcargaImagen,
        urlCargaImagen: urlCargaImagen,
        setUrlCargaImagen: setUrlCargaImagen,
        deshabilitarMenu: deshabilitarMenu,
        setDeshabilitarMenu: setDeshabilitarMenu,
        cerrarAppModal: cerrarAppModal,
        countFetching: countFetching,
        setCountFetching: setCountFetching,
        mostrarModalMenu: mostrarModalMenu,
        setMostrarModalMenu: setMostrarModalMenu,
        usuariosRecepcion: usuariosRecepcion,
        setUsuariosRecepcion: setUsuariosRecepcion,
        actividadesEconomicas: actividadesEconomicas,
        setActividadesEconomicas: setActividadesEconomicas,
        tiposPoblacion: tiposPoblacion,
        setTiposPoblacion: setTiposPoblacion,
        cerrarModal: cerrarModal,
        mostrarSpinner: mostrarSpinner,
        setMostrarSpinner: setMostrarSpinner,
      }}
    >
      {children}
    </ContextApplication.Provider>
  )
}
