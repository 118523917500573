import React, {useEffect, useContext} from 'react'
import {ContextApplication, ContextoEmpresa} from '../../contexto'
import {useHistory} from 'react-router-dom'
import FormularioAdicional from '../../componentes/Empresa/FormularioAdicional.jsx'
import FormularioDatosEmpresa from '../../componentes/Empresa/FormularioDatosEmpresa.jsx'
import FormularioFacturacion from '../../componentes/Empresa/FormularioFacturacion.jsx'
import {ObtenerCiudadPorNombre} from '../../cache/servicios-cache/Ciudades.js'
import BarraEncabezadosPestana from '../../componentes/Pestanas/BarraEncabezadosPestana.jsx'
import FormularioSeleccionEmpresa from '../../componentes/Empresa/FormularioSeleccionEmpresa.jsx'
import {ModificarEmpresa} from '../../microservicios/Empresa.js'
import ConfirmacionEmpresaCreada from '../../componentes/Empresa/ConfirmacionEmpresaCreada'
import {
  AgregarEmpresasACache,
  ObtenerEmpresas,
} from '../../cache/servicios-cache/Empresas.js'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina.jsx'
import {TEXTO_VISUAL} from '../../constantes/textoVisual.js'
import {
  transformarTipoFacturacion,
  UseObtenerRolValido,
} from '../../utilidades/funcionesComunes'
import FormularioUsuarioB2c from '../../componentes/Empresa/FormularioUsuarioB2c'
import {AgregarUsuariosACache} from '../../cache/servicios-cache'

const EditCompany = () => {
  const contextoApp = useContext(ContextApplication)
  const contextoEmpresa = useContext(ContextoEmpresa)
  const history = useHistory()

  useEffect(() => {
    contextoEmpresa.setTotalPaginas(5)
    contextoEmpresa.setNumeroPagina(1)
    contextoEmpresa.setModoEdicion(true)

    return () => {
      contextoEmpresa.setformularioDatos({})
      contextoEmpresa.setformularioFacturacion({})
      contextoEmpresa.setformularioAdicional({estaActiva: true})
      contextoEmpresa.setFormularioUsuarioB2c({})
    }
  }, [])

  useEffect(() => {
    AgregarUsuariosACache([], true)
  }, [])

  const modificarEmpresa = async () => {
    let ciudadId = (
      await ObtenerCiudadPorNombre(contextoEmpresa.formularioDatos.ciudad)
    )?.Id

    const contenido = {
      id: contextoEmpresa.idEmpresa,
      codigoInterno: contextoEmpresa.codigoInternoEmpresa,
      nit: contextoEmpresa.formularioDatos.nit,
      verificacionNit: contextoEmpresa.formularioDatos.verificacionNit,
      nombre: contextoEmpresa.formularioDatos.razonSocial,
      direccion: contextoEmpresa.formularioDatos.direccion,
      ciudadId: ciudadId,
      telefono: contextoEmpresa.formularioDatos.telefono,
      correo: contextoEmpresa.formularioDatos.correo,
      nombreEmpresaHC: contextoEmpresa.formularioDatos.nombreEmpresaHC,
      estaActiva: contextoEmpresa.formularioAdicional.estaActiva,
      motivoDesactivacion:
        contextoEmpresa.formularioAdicional.motivoDesactivacion,
      ejecutivoComercial:
        contextoEmpresa.formularioAdicional.ejecutivoComercial,
      observaciones: contextoEmpresa.formularioAdicional.observaciones,
      empresaPadreId: 0,
      logoUrl: contextoEmpresa.logoEmpresa.url,
      facturacionEmpresa: {
        id: contextoEmpresa.idEmpresa ?? 0,
        razonSocial: contextoEmpresa.formularioFacturacion.factura ?? '',
        rut53: contextoEmpresa.formularioFacturacion.RUT53 ?? '',
        rut54: contextoEmpresa.formularioFacturacion.RUT54 ?? '',
        codigoPostal: contextoEmpresa.formularioFacturacion.codigoPostal,
        actividadEconomica:
          contextoEmpresa.formularioFacturacion.actividadEconomica,
        actividadEconomicaId:
          contextoEmpresa.actividadEconomicaSeleccionada?.id ?? '',
        matriculaMercantil:
          contextoEmpresa.formularioFacturacion.matriculaMercantil,
        correoFacturacion:
          contextoEmpresa.formularioFacturacion.correoFacturacion,
        valorContrato: contextoEmpresa.formularioFacturacion.valorContrato,
        condicionPago:
          contextoEmpresa.formularioFacturacion.condicionesPago ?? null,
        descuento: contextoEmpresa.formularioFacturacion.descuento
          ? JSON.stringify(contextoEmpresa.formularioFacturacion.descuento)
          : '',
        impuesto: '',
        fechaCorte: contextoEmpresa.formularioFacturacion.fechaCorte,
        tipoFacturacion: transformarTipoFacturacion(
          contextoEmpresa.formularioFacturacion.tipoFacturacion
        ),
        observaciones:
          contextoEmpresa.formularioFacturacion.observacionesFacturacion ?? '',
        nombreContacto:
          contextoEmpresa.formularioFacturacion.nombreContacto ?? '',
        correoContacto:
          contextoEmpresa.formularioFacturacion.correoContacto ?? '',
        telefonoContacto:
          contextoEmpresa.formularioFacturacion.telefonoContacto ?? '',
        condicionesPrefactura:
          contextoEmpresa.formularioFacturacion.condicionesPrefactura ?? null,
        modalidadFacturacion:
          contextoEmpresa.formularioFacturacion.modalidadFacturacion ?? null,
        prefactura: contextoEmpresa.formularioFacturacion.prefactura ?? false,
        licitacion: contextoEmpresa.formularioFacturacion.licitacion ?? false,
      },
    }

    await ModificarEmpresa(contenido)
      .then(async (res) => {
        if (res.status === 200) {
          await AgregarEmpresasACache()
          await ObtenerEmpresas()

          contextoApp.setModal({
            abierto: true,
            titulo: 'DATOS EMPRESA ACTUALIZADA',
            contenido: (
              <ConfirmacionEmpresaCreada
                id={contextoEmpresa.idEmpresa}
                codigoCliente={contextoEmpresa.codigoInternoEmpresa}
                nombreEmpresa={contextoEmpresa.formularioDatos.razonSocial}
              />
            ),
            botones: [
              {
                nombre: 'Finalizar',
                click: manejarCerrarModalConfirmacion,
              },
              {
                nombre: 'Regresar',
                click: manejarModificarNueva,
              },
            ],
          })
        } else {
          return
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const manejarCerrarModalConfirmacion = () => {
    contextoApp.setModal({abierto: false, botones: []})
    history.push('/main')
  }

  const manejarModificarNueva = () => {
    history.go(0)
  }

  const listaPestanas = [
    'Lista empresas',
    'Datos de la empresa',
    'Facturación',
    'Información adicional',
    'Crear Usuario Del Portal Cliente',
  ]

  const rolValido = UseObtenerRolValido()

  useEffect(() => {
    if (
      contextoEmpresa.numeroPagina > 1 &&
      rolValido &&
      contextoEmpresa.formularioActualTieneErrores
    ) {
      contextoEmpresa.setformularioActualTieneErrores(false)
    }
  }, [
    contextoEmpresa.numeroPagina,
    contextoEmpresa.formularioActualTieneErrores,
    rolValido,
  ])

  return (
    <ContenedorPagina
      tituloPagina={
        rolValido
          ? TEXTO_VISUAL.EJECUTIVO_COMERCIAL.VER_EMPRESA
          : TEXTO_VISUAL.EJECUTIVO_COMERCIAL.VER_Y_MODIFICAR_EMPRESA
      }
    >
      <div className="w-full flex flex-wrap justify-center">
        <BarraEncabezadosPestana
          titulos={listaPestanas}
          contexto={contextoEmpresa}
        />

        {contextoEmpresa.numeroPagina === 1 ? (
          <div
            className={`rounded-md  -space-y-px p-10 flex justify-center flex-wrap my-3 items-center w-full`}
          >
            <FormularioSeleccionEmpresa numPag={1} />
          </div>
        ) : null}

        <div
          className={`rounded-md ${
            contextoEmpresa.numeroPagina === 2 ? '' : 'hidden'
          } -space-y-px p-10 flex justify-center flex-wrap my-3 items-center w-full`}
        >
          <FormularioDatosEmpresa modoSoloLectura={rolValido} numPag={2} />
        </div>
        <div
          className={`rounded-md ${
            contextoEmpresa.numeroPagina === 3 ? '' : 'hidden'
          } -space-y-px p-10 flex justify-center flex-wrap my-3 items-center w-full`}
        >
          <FormularioFacturacion modoSoloLectura={rolValido} numPag={3} />
        </div>
        <div
          className={`rounded-md ${
            contextoEmpresa.numeroPagina === 4 ? '' : 'hidden'
          } -space-y-px p-10 flex justify-center flex-wrap my-3 items-center w-full`}
        >
          <FormularioAdicional modoSoloLectura={rolValido} numPag={4} />
        </div>
        <div
          className={`rounded-md ${
            contextoEmpresa.numeroPagina === 5 ? '' : 'hidden'
          } -space-y-px flex justify-center flex-wrap my-3 items-center w-full`}
        >
          <FormularioUsuarioB2c
            funcionSubmit={modificarEmpresa}
            numPag={5}
            deshabilitado={true}
          />
        </div>
      </div>
    </ContenedorPagina>
  )
}

export default EditCompany
