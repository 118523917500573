import React, {useEffect, useContext} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import {useMsal} from '@azure/msal-react'
import moment from 'moment'
import Dexie from 'dexie'
import {ContextApplication, ContextoNavegacion} from '../../contexto'
import {ObtenerSedePorId} from '../../cache/servicios-cache'
import {rolRequiereConfiguracionSede} from '../../utilidades'
import Submenu from './Submenu'
import ImagenEstandar from '../../componentes/MostrarInformacion/ImagenEstandar'
import dotMenu from '../../imagenes/dotMenu.png'
import cerrarImg from '../../imagenes/cerrar.png'
import usuarioImg from '../../imagenes/usuarioImg.png'
import cambiarContrasena from '../../imagenes/cambiarContrasenaGris.svg'
import campana from '../../imagenes/menuRecepcion/campana.svg'
import imagenIntranet from '../../imagenes/intranet.png'
import imagenMesaDeAyuda from '../../imagenes/mesaDeAyuda.png'
import {FachadaFinalizarModificacionAtencion} from '../../microservicios'
import {ROLES_APP, RUTAS_APP} from '../../constantes'
import {
  fnObtenerInformacionSessionOLocalStorage,
  fnRemoverInformacionSessionOLocalStorage,
} from '../../utilidades/window.utils'

const MenuDinamico = ({datos}) => {
  const location = useLocation()

  const {instance, accounts} = useMsal()

  const rolUsuario = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''

  const sedeUsuario = fnObtenerInformacionSessionOLocalStorage(
    'sedeId',
    rolUsuario
  )

  let fechaActual = moment().format('DD/MM/YYYY')
  const ctx = useContext(ContextApplication)
  const contextoNavegacion = useContext(ContextoNavegacion)

  const nombreUsuario = accounts[0]?.idTokenClaims?.name

  useEffect(() => {
    if (sedeUsuario) {
      ObtenerSedePorId(JSON.parse(sedeUsuario)).then((res) => {
        ctx.setSede((prv) => ({...prv, nombreSede: res.Nombre}))
      })
    }
  }, [])

  useEffect(() => {
    abrirMenuAutomaticamente()
  }, [location])

  const abrirMenuAutomaticamente = () => {
    if (location.pathname == '/main') {
      contextoNavegacion.setAbrirMenu(true)
    }
  }

  const MostrarMenu = () => {
    if (contextoNavegacion.abrirMenu === true) {
      contextoNavegacion.setAbrirMenu(false)
      contextoNavegacion.setEnlaceActivo('')
    } else {
      contextoNavegacion.setAbrirMenu(true)
    }
  }
  const manejaCerrarModal = () => {
    ctx.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const cancelarEdicionUsuario = async () => {
    await FachadaFinalizarModificacionAtencion({
      atencionId: location?.state?.idAtencion,
      enviarCorreo: false,
    })
  }

  const manejarPermanecerEnPagina = () => {
    ctx.setModal({
      abierto: true,
      titulo: (
        <div className="w-full flex justify-center items-center text-center text-2xl font-bold">
          NO PUEDE SALIR DE LA TRANSACCIÓN
        </div>
      ),
      subtitulo: (
        <>
          <div className="w-full flex justify-center items-center text-center text-2xl font-medium">
            DEBE TERMINAR EL REGISTRO Y EMITIR EL COMPROBANTE
          </div>
          <div className="w-full flex justify-center items-center text-center text-2xl font-medium mb-10">
            CORRESPONDIENTE.
          </div>
          <div className="w-full flex justify-center items-center text-center text-2xl font-medium">
            FINALICE CON EL BOTÓN TERMINAR
          </div>
        </>
      ),
      contenido: null,
      botones: [
        {
          nombre: 'Regresar',
          click: manejaCerrarModal,
        },
      ],
    })
  }

  const manejarFuncionesAEjecutar = () => {
    if (ctx.mostrarModalMenu) {
      return manejarPermanecerEnPagina()
    }
    if (rolUsuario === ROLES_APP.JEFE_SEDE && location?.state?.idAtencion) {
      return cancelarEdicionUsuario()
    }
    return null
  }

  const MakeLogout = () => {
    ctx.setLoadingMessage('')
    localStorage.removeItem('siteId')
    localStorage.removeItem('siteName')
    sessionStorage.removeItem('cargaInicial')
    fnRemoverInformacionSessionOLocalStorage('consultorioId', rolUsuario)
    fnRemoverInformacionSessionOLocalStorage('recepcionId', rolUsuario)
    fnRemoverInformacionSessionOLocalStorage('sedeId', rolUsuario)
    fnRemoverInformacionSessionOLocalStorage('nombreCiudad', rolUsuario)
    fnRemoverInformacionSessionOLocalStorage('idCiudad', rolUsuario)

    if (instance) {
      instance.logoutRedirect()
    }

    //Fix Parcial CARLOS
    Dexie.delete('CendiatraCacheComun')
    Dexie.delete('CendiatraCacheComercial')
  }
  useEffect(() => {
    contextoNavegacion.setEnlaceActivo('')
  }, [contextoNavegacion.setEnlaceActivo])

  useEffect(() => {
    contextoNavegacion.setOpcionMenuSeleccionada('')
  }, [contextoNavegacion.abrirMenu])

  const paginaActual = window.location.pathname

  const abrirMenuSeleccionado = (nombreOpcion) => {
    contextoNavegacion.setOpcionMenuSeleccionada(
      contextoNavegacion.opcionMenuSeleccionada &&
        contextoNavegacion.opcionMenuSeleccionada === nombreOpcion
        ? ''
        : nombreOpcion
    )
  }

  return (
    <>
      <div
        className={
          contextoNavegacion.abrirMenu
            ? ' bg-transparent w-full h-full fixed flex  justify-start  content-start z-40'
            : 'hidden'
        }
        onClick={contextoNavegacion.abrirMenu ? () => MostrarMenu() : null}
      ></div>
      <div
        className={
          contextoNavegacion.abrirMenu
            ? 'bg-white sm:w-2/5 md:w-4/12 lg:w-4/12 xl:w-3/12 2xl:w-1/5 h-full fixed flex border  border-opacity-50 border-cendiatra shadow-2xl rounded flex-wrap justify-start content-start z-50 rounded-tr-3xl rounded-br-3xl transition-color duration-100'
            : 'bg-white w-5% 2xl:w-custom2xl  flex border h-full fixed  border-opacity-50 border-cendiatra shadow-2xl  flex-wrap justify-start content-start z-30 rounded-md transition-color duration-100'
        }
        onMouseLeave={() => contextoNavegacion.setEnlaceActivo('')}
      >
        <div
          className={
            contextoNavegacion.abrirMenu
              ? 'bg-white h-auto rounded-lg w-full transition-color duration-75'
              : 'bg-white w-full h-auto rounded-lg transition-color duration-75'
          }
        >
          <div
            className={`w-full  ${
              contextoNavegacion.abrirMenu ? 'h-28' : 'h-16'
            }  flex  justify-center items-center font-mono  ${
              contextoNavegacion.abrirMenu ? 'bg-bannerImg' : 'bg-cendiatra'
            } bg-center bg-cover ${
              contextoNavegacion.abrirMenu
                ? 'rounded-tr-2xl rounded-br-2xl'
                : 'rounded-md'
            } z-30 `}
            onMouseEnter={() => contextoNavegacion.setEnlaceActivo('')}
          >
            <div
              onClick={() => MostrarMenu()}
              className={
                contextoNavegacion.abrirMenu
                  ? 'flex w-1/12 justify-center items-center cursor-pointer -mt-20'
                  : 'flex w-4/12 justify-center items-center cursor-pointer -mt-10 ml-3 z-50'
              }
              style={{marginBottom: '-30px'}}
            >
              <img
                className={
                  contextoNavegacion.abrirMenu
                    ? 'h-6 w-2 text-white text-lg z-50 '
                    : 'h-6 w-2 xl:w-2 2xl:w-2  text-white text-lg z-50 -ml-2'
                }
                src={dotMenu}
                alt="dotMenu"
              ></img>
            </div>
            <div
              className={
                contextoNavegacion.abrirMenu
                  ? 'w-4/12 flex justify-center'
                  : 'w-1/3 hidden justify-center'
              }
            >
              <img className="w-10/12" src={usuarioImg} alt="usuarioImg" />
            </div>

            <div className="w-8/12 flex justify-center items-center flex-wrap ">
              <span
                className={
                  contextoNavegacion.abrirMenu
                    ? 'w-full h-auto text-16px text-white text-center font-bold'
                    : 'hidden'
                }
              >
                {nombreUsuario}
              </span>
              <span
                className={
                  contextoNavegacion.abrirMenu
                    ? 'w-full h-6 text-13px  text-white text-center flex items-center justify-center '
                    : 'hidden'
                }
              >
                Rol: {rolUsuario}
              </span>
              <span
                className={
                  contextoNavegacion.abrirMenu && ctx.sede.nombreSede
                    ? 'w-full h-6 text-13px text-white text-center flex items-center justify-center leading-15px'
                    : 'hidden'
                }
              >
                Sede: {ctx.sede.nombreSede}
              </span>
              <span
                className={
                  contextoNavegacion.abrirMenu
                    ? 'w-full h-6 text-13px text-white text-center flex items-center justify-center'
                    : 'hidden'
                }
              >
                {fechaActual}
              </span>
            </div>
          </div>
        </div>

        <div
          className="w-full overflow-y-auto z-30"
          style={{minHeight: '520px', maxHeight: '520px'}}
        >
          {!ctx.sede.sedeId && rolRequiereConfiguracionSede(rolUsuario) ? (
            <div className="w-full" style={{minHeight: '520px'}}></div>
          ) : (
            datos.map((elemento, padre) => (
              <div
                key={padre}
                className={
                  contextoNavegacion.abrirMenu
                    ? 'flex flex-wrap ml-5 '
                    : 'flex flex-wrap w-full'
                }
              >
                <Submenu
                  titulo={elemento.titulo}
                  imagen={elemento.imagen}
                  ruta={ctx.mostrarModalMenu ? paginaActual : elemento.ruta}
                  deshabilitado={ctx.deshabilitarMenu || elemento.deshabilitado}
                  funcion={manejarFuncionesAEjecutar}
                  funcionOnClick={() => abrirMenuSeleccionado(elemento.titulo)}
                  subMenus={elemento?.subMenus?.length}
                >
                  {elemento.subMenus &&
                  contextoNavegacion.opcionMenuSeleccionada ===
                    elemento.titulo ? (
                    <div
                      className="w-full bg-white rounded z-40 ml-5"
                      onClick={() => contextoNavegacion.setEnlaceActivo('')}
                      onMouseLeave={() =>
                        contextoNavegacion.setEnlaceActivo('')
                      }
                    >
                      {elemento.subMenus.map((subMenu, hijo) => (
                        <NavLink
                          to={
                            ctx.mostrarModalMenu ? paginaActual : subMenu.ruta
                          }
                          className="flex w-full justify-start flex-nowrap items-center p-2 rounded  border-b-2 z-50 hover:bg-gray-100"
                          onClick={manejarFuncionesAEjecutar}
                          key={`${padre}-${hijo}`}
                        >
                          <img
                            className="w-7 h-7"
                            src={subMenu.imagen}
                            alt="service"
                          />
                          <span className=" ml-2 w-auto">{subMenu.titulo}</span>
                        </NavLink>
                      ))}
                    </div>
                  ) : null}
                </Submenu>
              </div>
            ))
          )}
        </div>
        <div className="w-full overflow-y-auto">
          <hr className="mt-2 mb-2  -ml-4"></hr>
          <div
            className={`flex flex-wrap ${
              contextoNavegacion.abrirMenu ? 'ml-5' : '-ml-1'
            } `}
          >
            <Submenu
              titulo="Notificaciones"
              imagen={campana}
              deshabilitado={true}
            />
          </div>
          <hr className="mt-2 mb-2 -ml-3"></hr>

          <div
            className={
              contextoNavegacion.abrirMenu
                ? 'flex flex-wrap ml-5'
                : 'flex flex-wrap -ml-1'
            }
          >
            <Submenu
              titulo="Cambiar Contraseña"
              imagen={cambiarContrasena}
              deshabilitado={true}
            />
            <Submenu
              titulo="Cerrar Sesión"
              imagen={cerrarImg}
              funcion={MakeLogout}
              ruta={RUTAS_APP.HOME_APP}
            />
          </div>
        </div>
        <div
          className={`flex w-full justify-center items-center mt-7 flex-wrap ${
            contextoNavegacion.abrirMenu ? '-ml-4' : ''
          }`}
        >
          <button
            className={`${
              contextoNavegacion.abrirMenu ? 'w-4/12' : 'w-8/12 my-2'
            }  flex justify-center items-center flex-wrap`}
            onClick={() =>
              window.open('https://intranet-cendiatra.com', '_blank').focus()
            }
          >
            <ImagenEstandar
              estilosContenedor={'w-full flex justify-center items-center'}
              imagen={imagenIntranet}
              estilosImagen={`${
                contextoNavegacion.abrirMenu ? ' h-10' : ' h-6'
              }`}
              textoAlternativo={'intranet'}
            />
            <span
              className={`w-full text-cendiatra-gris-1 ${
                contextoNavegacion.abrirMenu ? '' : 'hidden'
              } text-center text-13px`}
            >
              INTRANET
            </span>
          </button>
          <button
            className={`${
              contextoNavegacion.abrirMenu ? 'w-4/12' : 'w-8/12 my-2'
            }  flex justify-center items-center flex-wrap`}
            onClick={() =>
              window
                .open('http://192.168.101.78/glpi/index.php?noAUTO=1', '_blank')
                .focus()
            }
          >
            <ImagenEstandar
              estilosContenedor={'w-full flex justify-center items-center'}
              imagen={imagenMesaDeAyuda}
              estilosImagen={`${
                contextoNavegacion.abrirMenu ? ' h-10' : ' h-6'
              }`}
              textoAlternativo={'ayuda'}
            />
            <span
              className={`w-full text-cendiatra-gris-1 ${
                contextoNavegacion.abrirMenu ? '' : 'hidden'
              } text-center text-13px`}
            >
              MESA DE AYUDA
            </span>
          </button>
        </div>
      </div>
    </>
  )
}

export default MenuDinamico
